<template>
  <div class="appointmentList-view">
      <div>
        <el-input
            class="w200 mb10 mr10"
            placeholder="请输入员工姓名"
            v-model="serchParams.name"
            clearable
        />
        <el-input
            class="w200 mb10 mr10"
            placeholder="请输入员工手机号"
            v-model="serchParams.phone"
            clearable
        />
        <el-button type="primary" class="myBtn" @click="serchquery">搜索</el-button>
        <el-button type="primary" class="myBtn" @click="reset">重置</el-button>
      </div>
    <el-button @click="add" class="el-button ml10 el-button--primary el-button--small">新增</el-button>
    <el-button @click="changeBoss" class="el-button ml10 el-button--primary el-button--small" v-if="userInfo.cardId">更换超管</el-button>
    <el-button @click="departmentManager" class="el-button ml10 el-button--primary el-button--small" v-if="userInfo.cardId">部门管理</el-button>

    <commonTable
      :tableData="tableData"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="queryParam.pageNum"
      :loading="loading"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column prop="name" align="center" label="昵称" v-if="userInfo.cardId">
          <template slot-scope="scope">
             <img :src="scope.row.avatarUrl" class="head-img"/>
             <span class="nickname">{{scope.row.nickName}}</span>
          </template>
        </el-table-column>

        <el-table-column prop="name" align="center" label="姓名" />
        <el-table-column prop="phone" align="center" label="手机号" />
        <el-table-column prop="position" align="center" label="职位" v-if="userInfo.cardId">
        </el-table-column>
        <el-table-column prop="personalWx" align="center" label="微信号" v-if="userInfo.cardId"/>
        <el-table-column label="角色" align="center">
          <template slot-scope="scope">
              <div v-if="scope.row.isBoss">超级管理员</div>
              <div v-for="item in scope.row.roleList" :key="item.id" v-else>
                 {{item.name}}
              </div>
          </template>
        </el-table-column>
        <el-table-column prop="departName" align="center" label="部门"/>

        <!-- <el-table-column prop="userType" align="center" label="名片角色" v-if="userInfo.cardId">
          <template slot-scope="scope">
            <template v-if="scope.row.userType == 1">管理员</template>
            <template v-else-if="scope.row.userType == 2">超级管理员</template>
            <template v-else>成员</template>
          </template>
        </el-table-column> -->

        <el-table-column prop="createTime" align="center" label="注册时间" />
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="downCard(scope.row)" v-if="scope.row.cardId">名片码</el-button>
            <el-button type="text" @click="userEdit(scope.row)">编辑</el-button>
            <el-button type="text" @click="userDetail(scope.row)" v-if="scope.row.cardId">详情</el-button>
            <el-button type="text" @click="delUser(scope.row)" v-else>删除</el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog :visible.sync="addDialogShow" append-to-body title="新增员工">
       <el-form label-width="100px" :model="formData" :rules="rules" ref="form">
         <el-form-item label="姓名" prop="name">
             <el-input placeholder="请输入姓名" v-model="formData.name"></el-input>
         </el-form-item>
         <el-form-item label="手机号" prop="phone">
           <el-input placeholder="请输入手机号" v-model="formData.phone"></el-input>
         </el-form-item>
         <el-form-item label="密码" prop="password">
           <el-input placeholder="请输入密码" v-model="formData.password" type="password"></el-input>
         </el-form-item>
         <el-form-item label="部门" prop="position">
           <el-input placeholder="请输入部门" v-model="formData.position"></el-input>
         </el-form-item>
         <el-form-item label="微信号" prop="personalWx" v-if="userInfo.cardId">
           <el-input placeholder="请输入微信号" v-model="formData.personalWx"></el-input>
         </el-form-item>
        
        <template v-if="checkPermission(['mkt'])">

          <el-form-item label="名片角色" prop="userType" v-if="formData.userType!== 2">
              <el-radio-group v-model="formData.userType" @input="radiogroupChange">
                <el-radio :label="0">员工</el-radio>
                <el-radio :label="1">管理员</el-radio>
              </el-radio-group>
          </el-form-item>

          <el-form-item label="角色" prop="roles" v-if="formData.userType!== 2 && (hasRoleShow)">
            <el-select v-model="formData.roleIdList" multiple placeholder="请选择" style="width: 100%">
              <el-option
                  v-for="item in roleList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </template>
        <template v-else>
           <el-form-item label="角色" prop="roles" v-if="formData.userType!== 2">
            <el-select v-model="formData.roleIdList" multiple placeholder="请选择" style="width: 100%">
              <el-option
                  v-for="item in roleList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </template>
       </el-form>
      <div class="flex-c-c">
        <el-button @click="addDialogShow=false">取消</el-button>
        <el-button
            class="save"
            type="primary"
            @click="addConfirm">确定</el-button>
      </div>
    </el-dialog>

    <!--更换超管弹窗-->
    <el-dialog
        title="更换超管"
        center
        :visible.sync="changeDialogVisible"
        :modal-append-to-body="false"
        width="30%"
        :close-on-click-modal="false"
    >
      <el-form
          label-width="auto"
          class="ruleForm d-flex flex-column align-items-center"
      >
        <el-form-item label="新超级管理员">
          <el-select
              v-model="newUserId"
              style="width: 240px"
              placeholder="请选择"
              required
          >
            <el-option
                v-for="(item, index) in allTableData"
                :key="index"
                :label="item.name"
                :value="item.userId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="changeDialogVisible=false">取 消</el-button>
        <el-button type="primary" @click="handleChangeBoss"
        >确 定</el-button
        >
      </span>

    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import service, {get, post, put, deletes} from "@/utils/request";

import commTable from "@/mixins/commTable";
import {fetchChangeBoss, getWxCodeImg, queryPageEmployee, updateEmployee} from "@/api/companyManage";
import utils from "@/utils";
import {mapGetters} from "vuex";
import {checkPermission} from "@/directives/permission";

export default {
  name: "user",
  mixins: [commTable],
  components: {
    commonTable,
  },
  data() {
    return {
      queryParam:{
        pageNum:1
      },
      serchParams:{name:'',phone:''},
      addDialogShow: false,
      formData: {},
      tableData: [],
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
      },
      roleList: [],
      departmentList: [],
      changeDialogVisible: false,
      allTableData: [],
      newUserId: null,
      userInfo: this.$store.state.loginRoot.userInfo,
      hasRoleShow: false, // 有名片的情况下，选角色要先判断有没有对应的角色
    };
  },
  mounted() {
    this.initList('/home/user',this.serchParams);
  },
  methods: {
    checkPermission,
    radiogroupChange(val) {
      console.log('val',val)
      let hasRoleShow = false
      if(val == 1) { // 选中管理员
        if(this.roleList.length) {
          let len  = this.roleList.filter(item => item.name === '管理员').length
          console.log('len', len)
          if(len >= 1) {
            hasRoleShow = false
          }else {
            hasRoleShow = true
          }
        }else {
          hasRoleShow = true
        }
      }else { //选中管理员 
        if(this.roleList.length) {
          let len  = this.roleList.filter(item => item.name === '员工').length
          console.log('len',len)
          if(len >= 1) {
            hasRoleShow = false
          }else {
            hasRoleShow = true
          }
        }else {
          hasRoleShow = true
        }
      }
      this.hasRoleShow = hasRoleShow
    },
    reset(){
      this.serchParams.name=''
      this.serchParams.phone=''
      this.queryParam.pageNum = 1
      this.serchquery()
    },
    serchquery(){
      this.queryParam.pageNum = 1
      this.initList('/home/user',this.serchParams);
    },
    downCard(data) {
      let params = {
        cardId: data.cardId
      }
      getWxCodeImg(params).then(res => {
        if(res.code === 200) {
          let url = res.data
          const a = document.createElement('a')
          a.href = url
          a.download = data.cardId
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    initFinish(){
      this.tableData.forEach(item=>{
        item.roleIdList=item.roleList.map(roleItem=>roleItem.id)
      })
    },
    initRoleList(){
      get('/company/role/list').then(res=>{
         this.roleList=res.list
      })
    },
    userEdit(row){
      delete  this.rules.password
      this.formData={
        userId: row.userId,
        roleIdList: row.roleIdList,
        phone: row.phone,
        name:row.name,
        position: row.position,
        personalWx:row.personalWx,
        userType: row.userType,
        cardId: row.cardId
        }
      get('/company/role/list').then(res=>{
         this.roleList=res.list
          // 初始化
          if(checkPermission(['mkt'])) {
            this.radiogroupChange(this.formData.userType)
          }else {
            this.hasRoleShow = true
          }
         this.addDialogShow=true
      })
    },
    userDetail(row){
      this.$router.push('/ent/memberDetail?id='+row.cardId)
    },
    delUser(row) {
      let params={
        id: row.userId
      }
      let that = this
      console.log('row',params)
      deletes('/home/user',params).then(res => {
        that.initList('/home/user',that.serchParams);
      })
    },
    add(){
      this.rules.password=[
        { required: true, message: '请输入密码', trigger: 'blur' }
      ]
      // this.addDialogShow=true;
      // this.initRoleList()
      get('/company/role/list').then(res=>{
         this.roleList=res.list
         // 初始化
        //  if(checkPermission(['mkt'])) {
        //    console.log('码客通权限')
        //  }
         this.addDialogShow=true
      })
    },
    changeBoss() {
      this.getAllCorporator()
      this.changeDialogVisible=true;
    },
    getAllCorporator() {
      let data = {
        pageNum:1,
        pageSize:1000,
        name:this.searchKey,
        queryType:'all',
      }
      queryPageEmployee(data).then((res) => {
        if(res.data.pageInfo.list) {
          this.allTableData = res.data.pageInfo.list.filter(item => item.userId!== this.userInfo.userId);
        }
      })
    },
    departmentManager(){
      this.$router.push('/ent/sectorSetup')
    },
    addConfirm(){
      this.$refs.form.validate((valid) => {
         if(valid){
           var method='post'
           if(this.formData.userId){
             method='put'
           }
           service({
             url: '/home/user',
             method: method,
             data: this.formData
           }).then(res=>{
             this.addDialogShow=false
             this.initList()
           })
         }
      })

    },
    handleChangeBoss() {
      if(!this.newUserId) {
        this.$message({
          message: "请选择新超管",
          type: "error",
        });
        return
      }
      let params = {
        companyId: this.userInfo.companyId,
        userId: this.userInfo.userId,
        toUserId: this.newUserId,
      }
      let _this = this
      fetchChangeBoss(params).then(res => {
        if(res.code === 200) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          _this.$router.push("/login")
          utils.clearLoginInfo()
        }
        this.closeChangeDialog()
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}
.head-img{
  width: 30px;
}
.nickname{
  line-height: 30px;
  vertical-align: top;
  margin-left: 4px;
}
</style>
